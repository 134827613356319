import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import BurgerMenu from '../../components/burger-menu';

export default ({ children }) => {
  return (
    <>
      <div className='flex-shrink-0 p-4 pl-6 w-64 mb-4 relative hidden lg:block text-blue-600'>
        <ul className='fixed h-nav overflow-y-auto'>{children}</ul>
      </div>
      <div className='block lg:hidden'>
        <BurgerMenu>{children}</BurgerMenu>
      </div>
    </>
  );
};

export const NavItem = ({ to, indent, children }) => {
  const classes = cn('mb-2 text-sm pt-2', {
    'ml-3': indent
  });

  return (
    <li className={classes}>
      <Link
        className='border-b-2 border-transparent hover:border-blue-400'
        to={to}
        activeClassName='border-blue-400'
      >
        {children}
      </Link>
    </li>
  );
};
