import React from 'react';
import { slide as Menu } from 'react-burger-menu';

const BurgerMenu = ({ children }) => {
  const styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '26px',
      height: '20px',
      left: '16px',
      top: '22px',
      marginRight: '30px'
    },
    bmBurgerBars: {
      background: '#9F7AEA'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#BDC3C7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: 'calc(100vh - 4rem)' // This is the height of the nav. Viewport minus header height
    },
    bmMenu: {
      background: '#1A212C',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },
    bmItemList: {
      color: '#FFF',
      padding: '0.8em',
      listStyle: 'none'
    },
    bmItem: {
      display: 'inline-block',
      borderBottom: 0
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  };

  return <Menu styles={styles}>{children}</Menu>;
};

export default BurgerMenu;
